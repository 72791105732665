import logo from "./logo.jpeg";
import "./App.css";

function App() {
  return (
    <div className="container">
      <img alt="" src={logo} />
      <p>Anai Nazario</p>
      <p>
        <a href="http://nani.pe/">www.nani.pe</a>
      </p>

      <a
        className="link"
        target="_blank"
        rel="noreferrer noopener"
        href="https://catalogs-nani-pe.s3.amazonaws.com/catalogo-29082022_optimized.pdf"
      >
        Catálogo
      </a>

      <a
        className="link"
        target="_blank"
        rel="noreferrer noopener"
        href="https://www.instagram.com/anainazario/"
      >
        Instagram
      </a>

      {/* <a
        className="link"
        target="_blank"
        rel="noreferrer noopener"
        href="https://twitter.com/andreaoviedov"
      >
        Twitter
      </a>
      <a
        className="link"
        target="_blank"
        rel="noreferrer noopener"
        href="https://www.linkedin.com/in/andreaoviedov/"
      >
        LinkedIn
      </a>
      <a
        className="link"
        target="_blank"
        rel="noreferrer noopener"
        href="https://www.youtube.com/channel/UCc7p4RYAZ1HNcFBvOwtvBmA"
      >
        Mi canal de YouTube
      </a> */}
    </div>
  );
}

export default App;
